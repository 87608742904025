<template>
  <v-container fluid>
    <v-data-table :headers="headers"
                  :items="summaryReport"
                  item-key="namespaceName"
                  show-expand
                  hide-default-footer
    >
      <template v-slot:item="{headers, item, expand, isExpanded}">
        <tr @click="expand(!isExpanded)">
          <td :colspan="headers.length">
            <span>&nbsp;{{item.namespaceName}}&nbsp;
              <a href="#">({{item.versions.length}} versions)</a>
              <v-icon small v-if="item.latestNameSpace" class="ml-1">mdi-check</v-icon>
            </span>&nbsp;
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{headers, item}">
          <td :colspan="headers.length">
          <VersionsDataTable :item="item"/>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import VersionsDataTable from './VersionsDataTable';

export default {
  components: {
    VersionsDataTable,
    // eslint-disable-next-line vue/no-unused-components

  },
  computed: {
    ...mapGetters({ summaryReport: 'newsummaryReportList' }),
  },
  methods: {
    ...mapActions({ loadData: 'READ_LIST_newsummaryReport' }),
    // childrenLoader(item) {
    //   console.log('childrenloader', item);
    // },
  },
  data() {
    return {
      headers: [
        {
          text: 'Namespaces',
          value: 'namespaceName',
          align: 'start',
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">  
  tbody {
     tr:hover {
        background-color: #e8e8fc !important;
     }
  }
</style>