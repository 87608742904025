<template>
    <v-data-table
            :items="item.dataSources"
            item-key="sourceId"
            :headers="headers"
            :items-per-page="100"
            hide-default-footer
    >
    <template v-slot:item.displayName="{item}">
      <span :class="(item.totalRead - item.entities) > 0 || item.failedRead? 'red' : ''" style="margin-right: 5px;display:inline-block;padding: 5px;border-radius:5px">&nbsp;&nbsp;</span>
      {{ item.displayName }}
    </template>
    </v-data-table>
</template>
<script>
export default {
  name: 'DatasourceDataTable',
  props: {
    item: {},
  },
  data() {
    return {
      headers: [
        { text: 'Source', value: 'displayName' },
        { text: '#entities', value: 'entities', align: 'end' },
        { text: 'Total', value: 'totalRead', align: 'end' },
        { text: 'Failed', value: 'failedRead', align: 'end' },
        { text: 'Category', value: 'category' },
      ],
    };
  },
};

</script>
<style lang="scss" scoped>
    /*::v-deep #datasourcesTable tr {*/
    /*    box-shadow: 0 0 0 0 !important;*/
    /*}*/
</style>

<!--{"displayName":"EU Consolidated Sanction","entities":2210,"section":"AML","sourceId":"eu_consolidated_sanction","category":"sanctions"}-->
