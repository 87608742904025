<template>
    <v-data-table dense show-expand
                  :items="item.versions"
                  item-key="versionId"
                  :headers="headers"
                  :items-per-page="100"
                  hide-default-footer
                  hide-default-header
    >
      <template v-slot:item="{headers, item, expand, isExpanded}">
        <tr @click="expand(!isExpanded)"  style="background: #EEEEEE">
          <td :colspan="headers.length">
              <v-icon small class="mr-3" v-if="item.isWarning">mdi-alert-outline</v-icon>
            <span> {{item.versionId}}&nbsp;
              <a href="#">  ({{item.dataSources.length}} data sources : {{item.createdAt}})</a>
              <v-icon small class="ml-1" v-if="item.latestVersion">mdi-check</v-icon>
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{headers, item}">
          <td :colspan="headers.length">
            <DatasourceDataTable :item="item"/>
          </td>
      </template>
    </v-data-table>
</template>
<script>
import DatasourceDataTable from './DatasourceDataTable';

export default {
  name: 'VersionsDataTable',
  components: { DatasourceDataTable },
  props: {
    item: {},
  },
  data() {
    return {
      headers: [
        { text: 'version Id', value: 'versionId' },
        { text: 'createdAt', value: 'createdAt' },
        { text: 'lastUsed', value: 'lastUsed' },
      ],
    };
  },
  methods: {
    checkWarnings() {
      this.item.versions.forEach((rowItem) => {
        rowItem.isWarning = rowItem.dataSources.some((dataSource) => {
          return dataSource.failedRead || dataSource.totalRead - dataSource.entities > 0
        });
      });
    }
  },
  created() {
   this.checkWarnings();
  }
};
</script>
